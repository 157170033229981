import React from 'react';

class WorklistModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isModalOpen: false };
        this.modalRef = React.createRef()
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickClose = this.handleClickClose.bind(this)
        this.handleClickModal = this.handleClickModal.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    handleClickModal() {
        this.setState({ isModalOpen: true });
    }

    handleClickClose() {
        this.setState({ isModalOpen: false });
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true)
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.isModalOpen) {
                this.setState({
                    isModalOpen: !this.state.isModalOpen
                })
            }
        }
    }

    kaigyo(text) {
        var regex = '<br>';
        return text.split(regex).map((line) => {
            if (line.match(regex)) {
                return (<div>React.createElement('br')</div>);
            } else {
                return (<div>{line}</div>);
            }
        });
    }

    render() {
        let modal;
        if (this.state.isModalOpen) {
            modal = (
                <div className="worklistmodal">
                    
                    <div ref={this.setWrapperRef} className="worklistmodal_inner" style={{ backgroundImage: `url(${this.props.backgroundimage})` }}>
                        <button className="closebutton" onClick={() => { this.handleClickClose() }}>close</button>
                        <div className="worklistmodal_inner_header">
                            <div className="worklistmodal_inner_header_information">
                                <div className="worklistmodal_inner_header_number">{this.props.number}</div>
                            <div className="worklistmodal_inner_header_title">{this.props.title}</div>
                                <div className="worklistmodal_inner_header_creator">{this.props.creator}</div>
                                
                                </div>
                            <a className="worklistmodal_inner_header_worldurl" href={this.props.worldurl} target="__blank">ワールドへ行く</a></div>
                        <div className="title">審査員コメント</div>
                        <div className="worklistmodal_inner_contents">
                            <div className={this.props.class}>
                                <div className="commentbox_information">
                                <div className="commentatorphoto"><img src={this.props.photo} /></div>
                            <div className="commentbox_header">
                                <div className="commentbox_header_commentator">{this.props.commentator}</div>
                                    <a className="commentbox_header_twittername" href={this.props.commentatortwittterurl} target="__blank">{this.props.commentatortwitttername}</a>
                                    </div>
                                    </div>
                            <div className="commentbox_comment">{this.props.comment}</div>
                            </div>
                            <div className={this.props.class2}>
                                <div className="commentbox_information">
                                    <div className="commentatorphoto"><img src={this.props.photo2} /></div>
                                <div className="commentbox_header">
                                    <div className="commentbox_header_commentator">{this.props.commentator2}</div>
                                        <a className="commentbox_header_twittername" href={this.props.commentatortwittterurl2} target="__blank">{this.props.commentatortwitttername2}</a>
                                    </div>
                                    </div>
                                <div className="commentbox_comment">{this.props.comment2}</div>
                            </div>
                            <div className={this.props.class3}>
                                <div className="commentbox_information">
                                    <div className="commentatorphoto"><img src={this.props.photo3} /></div>
                                <div className="commentbox_header">
                                    <div className="commentbox_header_commentator">{this.props.commentator3}</div>
                                        <a className="commentbox_header_twittername" href={this.props.commentatortwittterurl3} target="__blank">{this.props.commentatortwitttername3}</a>
                                    </div>
                                    </div>
                                <div className="commentbox_comment">{this.props.comment3}</div>
                            </div>
                            <div className={this.props.class4}>
                                <div className="commentbox_information">
                                    <div className="commentatorphoto"><img src={this.props.photo4} /></div>
                                <div className="commentbox_header">
                                    <div className="commentbox_header_commentator">{this.props.commentator4}</div>
                                        <a className="commentbox_header_twittername" href={this.props.commentatortwittterurl4} target="__blank">{this.props.commentatortwitttername4}</a>
                                    </div>
                                    </div>
                                <div className="commentbox_comment">{this.props.comment4}</div>
                            </div>
                            <div className={this.props.class5}>
                                <div className="commentbox_information">
                                    <div className="commentatorphoto"><img src={this.props.photo5} /></div>
                                    <div className="commentbox_header">
                                        <div className="commentbox_header_commentator">{this.props.commentator5}</div>
                                        <a className="commentbox_header_twittername" href={this.props.commentatortwittterurl5} target="__blank">{this.props.commentatortwitttername5}</a>
                                    </div>
                                </div>
                                <div className="commentbox_comment">{this.props.comment5}</div>
                            </div>
                            <div className={this.props.class6}>
                                <div className="commentbox_information">
                                    <div className="commentatorphoto"><img src={this.props.photo6} /></div>
                                    <div className="commentbox_header">
                                        <div className="commentbox_header_commentator">{this.props.commentator6}</div>
                                        <a className="commentbox_header_twittername" href={this.props.commentatortwittterurl6} target="__blank">{this.props.commentatortwitttername6}</a>
                                    </div>
                                </div>
                                <div className="commentbox_comment">{this.props.comment6}</div>
                            </div>
                            <div className={this.props.class7}>
                                <div className="commentbox_information">
                                    <div className="commentatorphoto"><img src={this.props.photo7} /></div>
                                    <div className="commentbox_header">
                                        <div className="commentbox_header_commentator">{this.props.commentator7}</div>
                                        <a className="commentbox_header_twittername" href={this.props.commentatortwittterurl7} target="__blank">{this.props.commentatortwitttername7}</a>
                                    </div>
                                </div>
                                <div className="commentbox_comment">{this.props.comment7}</div>
                            </div>
                            <div className={this.props.class8}>
                                <div className="commentbox_information">
                                    <div className="commentatorphoto"><img src={this.props.photo8} /></div>
                                    <div className="commentbox_header">
                                        <div className="commentbox_header_commentator">{this.props.commentator8}</div>
                                        <a className="commentbox_header_twittername" href={this.props.commentatortwittterurl8} target="__blank">{this.props.commentatortwitttername8}</a>
                                    </div>
                                </div>
                                <div className="commentbox_comment">{this.props.comment8}</div>
                            </div>
                            <div className={this.props.class9}>
                                <div className="commentbox_information">
                                    <div className="commentatorphoto"><img src={this.props.photo9} /></div>
                                    <div className="commentbox_header">
                                        <div className="commentbox_header_commentator">{this.props.commentator9}</div>
                                        <a className="commentbox_header_twittername" href={this.props.commentatortwittterurl9} target="__blank">{this.props.commentatortwitttername9}</a>
                                    </div>
                                </div>
                                <div className="commentbox_comment">{this.props.comment9}</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                )
        }

        return (
            <div className="worklist-button">
                <button onClick={() => { this.handleClickModal() }}>
                    審査員コメントを見る</button>
                {modal}
            </div>
        );
    }
}

export default WorklistModal;
import PropTypes from 'prop-types'
import React from 'react'

const News = props => ( <div id = "news"
    style = { props.timeout ? { display: 'none' } : {} } >
    News <div className = "news-title" > VRAA活動終了のお知らせ </div> <div className = "news-text" > この度、 VRAAはVRAA02を持って活動を終えることとしました。 <br />
    楽しみにしていただいていた方には突然のお知らせで申し訳ありません。 <br />
    これまで参加・ 楽しみにしてくださった方へ活動終了の経緯と想いをお伝えしたく、 この場をお借りします。 <br /> <br />
    VRAAは2018年の第0回VR建築コンテストを端緒として、 装いを新たにVRChatのワールドクリエイターに焦点を当てたアワードとして2019年からスタートしました。 <br />
    当時はワールドクリエイターにスポットライトをあてる企画はほとんどなかったため、 VRAAはワールドクリエイターの方たちの創造性にもっと目を向けて欲しいという想いの下、 はじめられたものでした。 
    <br /> <br />
    VRAAはこれまでメンバーの完全なボランティアによって運営を行ってきましたが、 メンバーそれぞれの事情で次第に十分な時間を割くことが難しくなっておりました。 そこでメンバー間で話し合いを行い、 十分なクオリティでの運営の継続が困難であるならば、 2020 年のVRAA02を持って、 VRAAの活動は終了するという判断に至りました。 < br / > < br / >
    改めて、 楽しみにしていただいていた方には突然のお知らせで申し訳ありません。 <br /> <br />
    一方で、 VRAAが活動開始当初に設定していた目的の観点から言えば、 VRChat公式によるワールドジャムやワールドクリエイター向けのイベントなどが増え、 ワールドクリエイターに次第にスポットライトが当たるようになっております。 そこにどれだけの貢献ができたかは分かりませんが、 そうした状況の変化から一定の面では当初の目的は達成できているのではないかと考えております。 ただ、 ワールドクリエイターの方たちがもっと活躍するためには、 もちろんまだまだ十分な環境が整っているとは言えません。 < br / >
    VRAAとしての活動は難しくなりましたが、 メンバーそれぞれは、 これからもバーチャル空間の発展のため、 ひとりのバーチャル存在として微力ながらなにか貢献できるよう活動していく所存です。 <br /> <br />
    なお、 VRAA01、 02 のポータルワールド、 ウェブサイトは削除せずに残し、 できるだけ維持できるよう努めますので、 引き続きお楽しみいただけたら幸いです。 <br /> <br />
    最後に、 VRAAを開催したことによって、 多くの素晴らしいワールドに出会えたことが運営メンバーにとっては最良の出来事でした。 バーチャル空間、 そしてワールドクリエイターには大きな可能性があると再認識できた2年でした。 <br /> <br />
    VRAAに参加していただいた方、 応援してくれた方、 審査員やスポンサー、 ポータルワールドに訪問してくれた方、 すべての方に感謝を申し上げます。 <br />
    これまでありがとうございました。 </div> </div>
)

News.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout:PropTypes.bool,
}

export default News
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import Logo from '../images/vraa_typo_vector.svg'
import scrollTo from 'gatsby-plugin-smoothscroll';

const Top = props => ( <
    header id = "top"
    style = { props.timeout ? { display: 'none' } : {} } >
    <
    div className = "vraatoplogo" > < img src = { Logo }
    width = "240px" / > < /div> <
    div className = "content" >

    <
    div className = "inner" >
    <
    div className = "catchcopy" > ヴァーチャルでリアルな < ruby className = "notes" > < span > 空間 < /span><rt className="notesruby">アーキテクチャ</rt > < /ruby>を拡げよう <
    /div> <
    div className = "topschedule" >
    VRAA02に提出された全89作品のリストを公開しました！ < br / >
    下へスクロールしてご覧ください。 <
    /div> <
    /div> <
    /div> <
    nav >
    <
    ul >
    <
    li > < Link to = "/vraa" >
    <
    button className = "topbutton" >
    What 's VRAA <
    div className = "cubebox" >
    <
    div className = "cube" >
    <
    div className = "front plane" > < /div> <
    div className = "back plane" > < /div> <
    div className = "top plane" > < /div> <
    div className = "bottom plane" > < /div> <
    div className = "left plane" > < /div> <
    div className = "right plane" > < /div> <
    /div> <
    /div>             <
    /button> <
    /Link> <
    /li> <
    li >
    <
    button className = "topbutton"
    onClick = {
        () => scrollTo('#wrapper-list') } >
    全提出作品リスト <
    div className = "cubebox" >
    <
    div className = "cube" >
    <
    div className = "front plane" > < /div> <
    div className = "back plane" > < /div> <
    div className = "top plane" > < /div> <
    div className = "bottom plane" > < /div> <
    div className = "left plane" > < /div> <
    div className = "right plane" > < /div> <
    /div> <
    /div>

    <
    /button>

    <
    /li> <
    li > < a href = "https://vrchat.com/home/launch?worldId=wrld_4165c26e-b033-4eea-a469-e5247048e39a&instanceId=91724&shortName=vanilla-chrissaxophone-6c786"
    target = "_blank" >
    <
    button className = "topbutton" >
    VRAA02 Portal World <
    div className = "cubebox" >
    <
    div className = "cube" >
    <
    div className = "front plane" > < /div> <
    div className = "back plane" > < /div> <
    div className = "top plane" > < /div> <
    div className = "bottom plane" > < /div> <
    div className = "left plane" > < /div> <
    div className = "right plane" > < /div> <
    /div> <
    /div>

    <
    /button> <
    /a>

    <
    /li> <
    li > < Link to = "/virtualsite" >
    <
    button className = "topbutton" >
    VRAA Virtual Site <
    div className = "cubebox" >
    <
    div className = "cube" >
    <
    div className = "front plane" > < /div> <
    div className = "back plane" > < /div> <
    div className = "top plane" > < /div> <
    div className = "bottom plane" > < /div> <
    div className = "left plane" > < /div> <
    div className = "right plane" > < /div> <
    /div> <
    /div>

    <
    /button> <
    /Link>

    <
    /li>

    {
        /*<li>
                  <button>
                    contents
                                <div className="cubebox">
                                    <div className="cube">
                                        <div className="front plane"></div>
                                        <div className="back plane"></div>
                                        <div className="top plane"></div>
                                        <div className="bottom plane"></div>
                                        <div className="left plane"></div>
                                        <div className="right plane"></div>
                                    </div>
                                </div>            
                  </button>

                </li>*/
    } <
    /ul> <
    /nav> <
    /header>
)

Top.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool,
}

export default Top
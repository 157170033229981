import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Top from '../components/Top'
import Main from '../components/Main'
import Footer from '../components/Footer'
import Theme from '../components/Theme'
import About from '../components/About'
import News from '../components/News'
import Profile from '../components/Profile'
import ProfileAbout from '../components/ProfileAbout'
import Header from '../components/Header'
import holonpic from '../images/holon.png'
import Modal from '../components/Modal'
import Worklist from '../components/Worklist'
import WorklistModal from '../components/WorklistModal'
import pic05 from '../images/award2020_banner.jpg'
import pic_fujii from '../images/Fujii_Naotaka.gif'
import pic_yoshida from '../images/yoshida.jpg'
import pic_toyoda from '../images/Toyoda_Keisuke.jpg'
import pic_uka from '../images/Todoli_Uka.png'
import pic_twitter from '../images/Twitter_Social_Icon_Square_White.png'
import pic_ochiai from '../images/Ochiai_Yoichi.jpg'
import pic_docomo from '../images/docomo.jpg'
import pic_kddi from '../images/KDDI.png'
import pic_dh from '../images/dh.png'
import pic_bm from '../images/brainmagic.png'
import Logo from '../images/top_mainimage.png'

class IndexPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isArticleVisible: false,
            isArticleVisible2: false,
            isArticleVisible3: false,
            timeout: false,
            timeout2: false,
            timeout3: false,
            articleTimeout: false,
            articleTimeout2: false,
            articleTimeout3: false,
            article: '',
            loading: 'is-loading'
        }
        this.handleOpenArticle = this.handleOpenArticle.bind(this)
        this.handleCloseArticle = this.handleCloseArticle.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.handleOpenArticle2 = this.handleOpenArticle2.bind(this)
        this.handleCloseArticle2 = this.handleCloseArticle2.bind(this)
        this.setWrapperRef2 = this.setWrapperRef2.bind(this)
        this.handleClickOutside2 = this.handleClickOutside2.bind(this)
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ loading: '' });
        }, 100);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleOpenArticle(article) {

        this.setState({
            isArticleVisible: !this.state.isArticleVisible,
            article
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                articleTimeout: !this.state.articleTimeout
            })
        }, 350)

    }

    handleCloseArticle() {

        this.setState({
            articleTimeout: !this.state.articleTimeout
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                isArticleVisible: !this.state.isArticleVisible,
                article: ''
            })
        }, 350)

    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.isArticleVisible) {
                this.handleCloseArticle();
            }
        }
    }

    componentDidMount2() {
        this.timeoutId2 = setTimeout(() => {
            this.setState({ loading: '' });
        }, 100);
        document.addEventListener('mousedown', this.handleClickOutside2);
    }

    componentWillUnmount2() {
        if (this.timeoutId2) {
            clearTimeout(this.timeoutId2);
        }
        document.removeEventListener('mousedown', this.handleClickOutside2);
    }

    setWrapperRef2(node) {
        this.wrapperRef2 = node;
    }

    handleOpenArticle2(article) {

        this.setState({
            isArticleVisible2: !this.state.isArticleVisible2,
            article
        })

        setTimeout(() => {
            this.setState({
                timeout2: !this.state.timeout2
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                articleTimeout2: !this.state.articleTimeout2
            })
        }, 350)

    }

    handleCloseArticle2() {

        this.setState({
            articleTimeout2: !this.state.articleTimeout2
        })

        setTimeout(() => {
            this.setState({
                timeout2: !this.state.timeout2
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                isArticleVisible2: !this.state.isArticleVisible2,
                article: ''
            })
        }, 350)

    }

    handleClickOutside2(event) {
        if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
            if (this.state.isArticleVisible2) {
                this.handleCloseArticle2();
            }
        }
    }

    render() {
        return ( <Layout location = { this.props.location } >
            <div className = { `body ${this.state.loading} ${this.state.isArticleVisible2 ? 'is-article-visible' : ''}` } >
            <div>
            <Header />
            <div id = "wrapper" >
            <Top timeout = { this.state.timeout2 } /> </div> 
            
            <div id = "wrapper-news"
            className = { `wrapper-news ${this.state.loading} ${this.state.isArticleVisible ? 'is-article-visible' : ''}` } >
            
            <News onOpenArticle = { this.handleOpenArticle }
            timeout = { this.state.timeout } />
            
            </div>
            
            <div id = "wrapper-theme"
            className = { `wrapper-theme ${this.state.loading} ${this.state.isArticleVisible ? 'is-article-visible' : ''}` } >

            <Theme onOpenArticle = { this.handleOpenArticle }
            timeout = { this.state.timeout }/> <About isArticleVisible = { this.state.isArticleVisible }
            timeout = { this.state.timeout }
            articleTimeout = { this.state.articleTimeout }
            article = { this.state.article }
            onCloseArticle = { this.handleCloseArticle }
            setWrapperRef = { this.setWrapperRef }
            /> <div className = "twitter" > <a class = "twitter-timeline" href = "https://twitter.com/vraa_info?ref_src=twsrc%5Etfw" > Tweets by vraa_info </a> <script async src="https:/ / platform.twitter.com / widgets.js " charset="
            utf - 8 "></script></div> </div>

            <div id = "wrapper-profile" >
            <div className = "sectiontitle" > 審査員 </div> <div className = "profile-box" >
            <div id = "profile" >
            <Profile name = '藤井直敬'
            image = { pic_fujii }
            title1 = 'XRコンソーシアム代表理事'
            title2 = 'ハコスコ 代表取締役'
            title3 = 'デジタルハリウッド大学 教授' />
            <div className = "profile_twitter" > <a href = "https://twitter.com/NaotakaFujii"
            target = "_blank"> <img src = { pic_twitter }
            width = "20%" /> </a></div>
            </div> <div id = "profile" >
            <Profile name = '落合陽一'
            image = { pic_ochiai }
            title1 = 'XRコンソーシアム理事'
            title2 = 'メディアアーティスト'
            title3 = '筑波大学准教授' />
            <div className = "profile_twitter" > <a href = "https://twitter.com/ochyai"
            target = "_blank" > <img src = { pic_twitter }
            width = "20%" /> </a></div>
            </div> <div id = "profile" >
            <Profile name = '吉田修平'
            image = { pic_yoshida }
            title1 = 'XRコンソーシアム理事'
            title2 = 'ソニー・インタラクティブエンタテインメント インディーズ イニシアチブ 代表'
            title3 = '' />
            <div className = "profile_twitter" > <a href = "https://twitter.com/yosp"
            target = "_blank" > < img src = { pic_twitter }
            width = "20%" / > </a></div>
            </div> <div id = "profile">
            <Profile name = '豊田啓介'
            image = { pic_toyoda }
            title1 = '建築家'
            title2 = 'noiz共同主宰'
            title3 = 'gluon共同主宰' />
            <div className = "profile_twitter" > <a href = "https://twitter.com/toyoda_noiz"
            target = "_blank" > <img src = { pic_twitter }
            width = "20%" /> </a></div>
            </div> <div id = "profile" >
            <Profile name = '届木ウカ'
            image = { pic_uka }
            title1 = 'バーチャルドール'
            title2 = '3Dモデラー'
            title3 = 'AI×SFプロジェクトサポーター' />
            <div className = "profile_twitter" > <a href = "https://twitter.com/todoki_uka"
            target = "_blank" > <img src = { pic_twitter }
            width = "20%" /> </a></div>
            </div>

            </div> </div> <div id = "wrapper4">
            <div className = "vraaintroducetext">
            <div className = "title" > VRAAはVRワールドクリエイターなら誰でも参加できるデザインアワードです </div> <div className = "texttitle" > VRAAに参加すると… </div> <div className = "text" > ・応募作品がポータルワールドに集結！ 多くの人が訪れるワールド巡りの拠点に作品が配置されます。 < br / > ・年に一度のアーカイブを通して、 ワールド作品が存在したことをしっかり未来に残していきます。 < br / > ・コミュニティDiscordや各種イベントなど、 クリエイターの交流の場に参加できます。 < br / > ・運営や審査員が作品へのコメントを残します。 </div></div>
            <div className = "vraaintroducedetail">
            <div className = "vraamovie">
            <div className = "yt" > <iframe width = "800"
            height = "560"
            src = "https://www.youtube.com/embed/y2t5l0_oMfU"
            frameborder = "0"
            allow = "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen > </iframe></div>
            </div> <div className = "vraaintroduce">

            <div class = "wrapper4button">
            <a href = "https://discord.gg/QJVZFHD"
            target = "_blank" > <button > VRAAdiscordに参加する </button></a>
            <Link to = "/outline"> <button> 登録する </button></Link>
            </div>

            <div className = "holon" > <a href = "https://xrarchi.org/vraa1/vraa-girl/"
            target = "_blank" > <img src = { holonpic }
            width = "100%" /> </a><div className="holonname">VRAA応援キャラクター:ほろんちゃん</div> </div> </div> </div> </div> <div id = "wrapper-list">
            <Worklist />
            </div> <div id = "wrapper5">
            <div className = "sectiontitle" > VRAA02はXR CREATIVE AWARD 2020 と併催！ </div> <div className = "xrcaimage"> <a href = "http://xrc.or.jp/award2020/"
            target = "_blank"> <img src = { pic05 }
            width = "100%" /> </a></div>
            </div> <div id = "wrapper6">
            <div className = "sectiontitle" > スポンサー </div>

            <div className = "sponcer" > <div className = "sponcerclass" > プラチナスポンサー </div><div className="dh"><a href="https:/ / www.dhw.ac.jp / " target="
            _blank "><img src={pic_dh} width="
            100 % " /></a></div><div className="
            sponcername ">デジタルハリウッド大学</div> <div className = "sponcerg" > <div className = "sponcerclass" > ゴールドスポンサー </div><div className="g"><div className="docomo"><a href="https:/ / xr.docomo.ne.jp / ? utm_source = xrcreativeaward & utm_medium = referral & utm_content = banner " target="
            _blank "><img src={pic_docomo} width="
            100 % " /></a><div className="
            sponcername ">NTTドコモ</div></div> <div className = "kddi" > <a href = "https://www.kddi.com/"
            target = "_blank"> <img src = { pic_kddi }
            width = "70%" /> </a><div className="sponcername">KDDI</div> </div></div> </div> <div className = "sponcerclass" > シルバースポンサー </div><div className="dh"><a href="https:/ / brainmagic.tokyo / " target="
            _blank "><img src={pic_bm} width="100%" /></a></div><div className="sponcername">BRAIN MAGIC</div> <div className = "sponcer1" > <br /> <br /> 本アワードへのご協賛につきましては、 併催中の <a href = "http://xrc.or.jp/award2020/"
            target = "_blank" > <u> XR Creative Award 2020 </u></a> にてご案内差し上げております。 VRAAへのご賛同・ ご支援をお願いいたします。 </div></div>
            </div> <div id = "wrapper-footer">
            <Footer timeout = { this.state.timeout } /> </div><div id = "bg"> </div> </div> </div> <Helmet>
            <html lang = "jp" />
            <title> VRAA02 </title> <meta name = "description"
            content = "Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。今年のテーマは「Live/Frontier」新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！" />
            <meta property = "og:site_name"
            content = "VRAA02" />
            <meta property = "og:image"
            content = 'https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png' />
            <meta name = "twitter:card"
            content = "summary_large_image" />
            <meta name = "twitter:title"
            content = "VRAA02" />
            <meta name = "twitter:description"
            content = "Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。今年のテーマは「Live/Frontier」新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！" />
            <meta name = "twitter:image"
            content = 'https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png' />
            </Helmet> </Layout>


        )
    }
}

export default IndexPage